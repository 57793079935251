<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>
      <div class="d-flex justify-space-between mb-2">
        <div>
          <span class="mr-4 text--secondary">Résultats :</span>
          <span>Questionnaires</span>
          <v-chip color="light" class="ml-1" text-color="primary" small>15</v-chip>
          <v-btn text class="text-none rounded-lg ml-1" large>
            <v-icon left>mdi-refresh</v-icon>
            Réinitialiser
          </v-btn>
          <v-btn text class="text-none rounded-lg" large>
            <v-icon class="mr-1">mdi-file-pdf-box</v-icon>
            Télécharger en pdf
          </v-btn>
        </div>
        <div>
          <v-select style="margin-bottom: -16px" dense outlined label="Sélectionnez un enquête"/>
        </div>
      </div>
      <v-row>
        <v-col cols="4">
          <v-card flat class="rounded-lg">
            <v-card-title>
              <span class="title-card">What is the best brand ?</span>
              <v-spacer/>
              <v-btn fab depressed small color="btn--action">
                <v-icon>
                  mdi-download-outline
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <AreaChart/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat class="rounded-lg">
            <v-card-title>
              <span class="title-card">What is the best color ?</span>
              <v-spacer/>
              <v-btn fab depressed small color="btn--action">
                <v-icon>
                  mdi-download-outline
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <PieChart/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat class="rounded-lg">
            <v-card-title>
              <span class="title-card">Select the best version ?</span>
              <v-spacer/>
              <v-btn fab depressed small color="btn--action">
                <v-icon>
                  mdi-download-outline
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <BarChart/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat class="rounded-lg">
            <v-card-title>
              <span class="title-card">Are you happy  ?</span>
              <v-spacer/>
              <v-btn fab depressed small color="btn--action">
                <v-icon>
                  mdi-download-outline
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <RadarChart/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat class="rounded-lg">
            <v-card-title>
              <span class="title-card">What is the best brand ?</span>
              <v-spacer/>
              <v-btn fab depressed small color="btn--action">
                <v-icon>
                  mdi-download-outline
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <LineChart/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AreaChart from "./charts/AreaChart.vue";
import PieChart from "./charts/PieChart.vue";
import BarChart from "./charts/BarChart.vue";
import RadarChart from "./charts/RadarChart.vue";
import LineChart from "./charts/LineChart.vue"
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  components: {
    Breadcrumbs,
    AreaChart,
    PieChart,
    BarChart,
    RadarChart,
    LineChart
  },
  data() {
    return {
      breadcrumbs: [
        {text: 'Dashboard', disabled: false, to: '/'},
        {text: 'Analyses', disabled: false},
        {text: 'Résultats', disabled: false},
        {text: 'Nom de Campagne', disabled: false},
        {text: 'Nom de l\'Enquette', disabled: false},
      ],
      chartData: {
        Books: 24,
        Magazine: 30,
        Newspapers: 10
      }
    };
  }
};
</script>
<style scoped>
.title-card {
  font-size: 17px;
}
</style>
